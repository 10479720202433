import * as React from "react"

import Layout from "../components/layout"
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import Seo from "../components/seo"
import Container from 'react-bootstrap/Container';
import { graphql } from 'gatsby'

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("Page Not Found")} />
      <Container>
        <div className="d-flex align-items-center justify-content-center vh-80">
          <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3"> <span className="text-danger">{t("Opps")}!</span> {t("Page Not Found")}.</p>
            <p className="lead">{t("You just hit a page that doesn't exist... the sadness.")}</p>
            <Link to="/" className="btn btn-danger">{t("Go Home")}</Link>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
